// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.sidebar__link {
    width: 100%;
}

.sidebar__submenu .sidebar__link {
    padding-left: 20px;
}

.topBarImageAlignment {
    text-align: center;
}

.changemodal {
    width: 335px;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightOthersCreated {
    background-color: #6db6be;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightForLessHours {
    background-color: #fdad8a;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightForZeroHours {
    background-color: #7dfcf5;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

//auto complete mwnu list is moving up
.p-autocomplete-panel {
    // position: unset !important;
    margin-top: 26px
}

.p-autocomplete .p-autocomplete-panel {
    top: 10% !important;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 350px;
}

.redBackground {
    border-width: 1px;
    border-color: #ff0000;
    // border-radius: 10%;
    padding: 20px;
    background-color: #fff !important;
    width: '50%';
    border-radius: 8px;
}

.ApraisalWrngText {
    color: white;
    font-size: 14px
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: #f17038;
    color: black;
}


.colorLegend {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.p-datatable-emptymessage {
    text-align: center;
}

.sidebar__submenu-wrap {
    padding-left: 20px;
}

//autocomplete height
.p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input {
    height: 33px;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    margin: 1px !important;
}

.p-autocomplete-token .p-autocomplete-token-label {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px
}

.form-control {
    width: '90%' !important
}

.form-control {
    width: '90%' !important
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: #f17038 !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #ffffff !important;
    background-color: #f17038 !important;
    color: #ffffff;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    margin-top: 10px !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0 0.857em !important;
}

.birthDayBackground {
    border-width: 2px;
    border-color: #05f3e7;
    // border-radius: 10%;
    padding: 20px;
    background-color: #ffffff !important;
    width: '50%';
    border-radius: 8px;
}

.fontbold {
    font-weight: bold;
}

.employeeCommentColor {
    color: blue;

}

.checkbox-btn__checkbox-custom {
    border: 1px solid #757677;
}

.detailsImgStyle {
    height: 120px;
}

body .p-multiselect .p-multiselect-label {
    height: 25px;
    margin: 0em;
}

.topbar__menu-wrap {
    padding: 0 0px !important;
    min-width: 10px !important;
}

.overflowhidden {
    overflow: hidden;
}

.primefileupload .p-fileupload.p-component {
    max-width: 100%;
}

.primefileupload .p-fileupload.p-component .p-fileupload-buttonbar .p-button {
    margin-bottom: 4px;
}

.primefileupload .p-fileupload.p-component .p-fileupload-content {
    padding-left: 5px;
    padding-right: 5px;
}

.primefileupload .p-fileupload.p-component {
    .p-fileupload-row>div:nth-child(1) {
        display: none;
    }

    .p-fileupload-row>div:nth-child(2) {
        max-width: 195px;
        width: 70%;
        padding-left: 0;
        padding-right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .p-fileupload-row>div:nth-child(3) {
        padding: 0 5px;
    }

    .p-fileupload-row>div:nth-child(4) {
        padding: 0;
    }
}

//CSS for sprint table fields
body .p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
    width: 80% !important
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    width: 20% !important
}

.p-calendar .p-datepicker {
    min-width: 21rem;
}